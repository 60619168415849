import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Heading from "../components/heading/heading";
import ScrollAnimation from 'react-animate-on-scroll';

const PrivacyPolicyPage = () => (
  <Layout>
    <SEO title="Privacy Policy" />
    <ScrollAnimation className="mt-5" animateIn="fadeIn" animateOnce={true} style={{marginBottom: 150}}>
      <Heading
        title="Privacy Policy"
        marginBottom={25}
      />
      <p>Effective Date: 8/14/2020</p>
      <p>This privacy policy applies to your use of the RetailStack Commerce Platform mobile application (the &ldquo;App&rdquo;) operated by RetailStack, LLC (&ldquo;we,&rdquo; &ldquo;us&rdquo; and &ldquo;our&rdquo;) and linking to this policy. This policy outlines our practices and policies with respect to our collection and handling of your personal information through and in connection with the App. Please review this policy carefully.</p>
      <p>This policy applies to information we collect:</p>
      <ul>
        <li>on or through the App;</li>
        <li>in email and other electronic communications between you and us through or relating to the App.</li>
      </ul>
      <p>This policy does NOT apply to information collected by:</p>
      <ul>
        <li>us offline or through other means not provided by you (if any); or</li>
        <li>any third party, including through any application or website that may link to or be accessible through the App.</li>
      </ul>

      <br></br>
      <h5>Information Collected</h5>
      <p>We collect personally identifiable information (information that identifies an individual person) that you submit to the App, which includes or may include the following:</p>
      <ul>
        <li>your name</li>
        <li>your location</li>
      </ul>
      <p>Additionally, we collect information you submit about the labels you print and other information you submit to the App or send to us in connection with the App.</p>
      <p>The above describes information that you would deliberately submit to us or the App. We also may collect technical information you do not deliberately send to us such as: your IP address, time and date information and other meta- and technical information relating to your use of the App.</p>
      <p>Note that we use third party commercial hosting and related infrastructure located in the United States. Therefore, if you are located outside the United States, information we collect from you will be transferred to the United States. The App is intended for use by persons located in the United States. We do not undertake compliance with data protection and privacy or other laws outside the United States including European Union data protection laws. If you are located outside the United States and are concerned about transferring your information to the United States or about compliance with privacy or other laws outside the United States, you should not use the App.</p>
      
      <br></br>
      <h5>How Information Collected is Used</h5>
      <p>We only use your information to operate and maintain the App, to provide the services offered through the App, to communicate with you about the App including announcements and offers, and to analyze how the App is used and to improve the App and its offerings. We may also transmit information between you and other members of the App and use your information to enforce our Terms of Service and other legal rights, or to assist in resolving disputes, including between you and us or you and other App users.</p>
      <p>We may also disclose information about you if we determine that disclosure is reasonably necessary to enforce our terms and conditions or protect our operations or users or to comply with law, including complying with a subpoena or the reasonable requests of law enforcement. Additionally, in the event of our reorganization, merger, or sale we may transfer any and all personal information we collect to the relevant third party.</p>
      
      <br></br>
      <h5>How We Share Your Information with Third Parties</h5>
      <p>We may share your information with third parties as follows:&nbsp;</p>
      <ul>
        <li>We may share your information with third party service providers for the purposes of operating the App. For example, we use third party hosting infrastructure services, technical consultants and other similar third party technical service providers to operate the App that may involve sharing of or granting access to your information.</li>
        <li>We may share your information with other users of the App in the operation of the App (for example, labels you print, etc).</li>
        <li>We may sell, rent or otherwise provide to third parties your information aggregated with other App user data in an aggregated, anonymized form that does not identify you.</li>
        <li>We may share your information with law enforcement or other governmental entities.</li>
        <li>In the event of our reorganization, merger, or sale we may transfer any and all information we collect from you to the relevant third party.</li>
      </ul>

      <br></br>
      <h5>Tracking Technologies</h5>
      <ul>
        <li>The App may use tracking technologies. These technologies are either strictly necessary for you to use the App (for example, to determine the location you are using the app), functional (for recording printed labels) or for analytical purposes (to analyze App usage in an aggregated manner). We use these technologies to operate and improve the App and to generate anonymized performance and analytic data. Such technologies may enable us to collect certain of your location or technical information like device identifiers, information about how, when and where you used the App and similar information.</li>
      </ul>

      <br></br>
      <h5>Accessing, Correcting, or Deleting your Information</h5>
      <p>Certain of your personal information you can access, modify or delete through the App such as your profile information. Certain required information you can only modify, but not leave blank. See your profile page for this information. Other information you submit cannot be modified or removed by you once submitted.</p>
      <p>If you wish to terminate your user account, you may request that we do so by contacting us at the email below in this section, however any such termination will not remove your information stored in the App.</p>
      <p>If you wish to have any of your information on the App deleted, modified, or corrected in a manner that you are not able to accomplish yourself through the App, you can send a request with appropriate details to the contact information below. We will make an effort, within a reasonable time thereafter, to accommodate your request although technical, practical, and other considerations may limit or prevent our ability to do so.&nbsp;</p>
      
      <br></br>
      <h5>Use of the App by Children</h5>
      <p>The App is not directed to or intended for children under eighteen (18) years of age. We do not knowingly collect personal information of or from such people. Please do not submit any information, personal or otherwise, to the App regarding a person under the age of eighteen. If such information has nevertheless been submitted to us, please contact us at the contact information below so that we can endeavor to remove such information from the App.</p>
      
      <br></br>
      <h5>How We Protect Your Information</h5>
      <p>We take various measures to protect your privacy and data, but we cannot ensure or warrant the security of any information you transmit to us or the App or guarantee that such information will not be accessed, disclosed, altered or destroyed by breach of any of our physical, technical or managerial safeguards. When you enter sensitive information (such as your password) on our registration or order forms, we encrypt that information using secure socket layer technology (SSL). No method of transmission over the Internet or method of electronic storage, is 100% secure, however. Therefore, we cannot guarantee your information&rsquo;s absolute security.</p>
      
      <br></br>
      <h5>Notification Procedures</h5>
      <p>We provide notifications to you, including those in general operation of the App, required by law and for marketing or other business related purposes, via email, written or hard copy notice, or through posting of the notice on the App, as determined by us in our sole discretion. We reserve the right to determine the form and means of providing notifications to you, provided that you may opt out of certain means and types of notifications.</p>
      
      <br></br>
      <h5>Changes to Our Privacy Policy</h5>
      <p>We may update this Privacy Policy from time to time by posting a new version on the App and we will update the effective date above to reflect the date of the changes. You should visit this page occasionally to ensure you agree with any changes.</p>
      
      <br></br>
      <h5>Contact Information</h5>
      <p>You can direct questions and comments regarding this policy to <a href="mailto:info@retailstack.com">info@retailstack.com</a>.</p>
    </ScrollAnimation>
  </Layout>
)

export default PrivacyPolicyPage